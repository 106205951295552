import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '../tools/withStyles';
import { Main } from '../components/Main';
import { Secuence } from '../components/Secuence';
import { Text } from '../components/Text';
import { Fader } from '../components/Fader';
import { Link } from '../components/Link';
import { MatrixText, ScanlineText } from '../components/TextEffects';

const styles = theme => ({
  root: {},
  diary: {
    marginBottom: 40
  },
  diaryEntry: {
    marginBottom: 30,
    padding: 25,
    background: 'rgba(10, 11, 15, 0.3)',
    border: '1px solid #033232',
    borderRadius: 4,
    '&:hover': {
      border: '1px solid #88C0D0',
      transition: 'border-color 0.3s ease'
    }
  },
  diaryDate: {
    color: '#88C0D0',
    fontSize: 14,
    marginBottom: 15,
    opacity: 0.9,
    fontFamily: "'JetBrains Mono', monospace"
  },
  diaryContent: {
    color: '#CCCCCC',
    lineHeight: 1.8,
    whiteSpace: 'pre-line',
    '& p': {
      marginBottom: 15
    }
  },
  albums: {
    marginTop: 60,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  album: {
    padding: [0, 0, 20],
    width: '100%'
  },
  albumCover: {
    display: 'block',
    margin: [0, 0, 20],
    width: '100%',
    '& img': {
      margin: 0,
      width: '100%',
      maxWidth: '100%'
    }
  },
  albumFrame: {
    display: 'block',
    width: '100%'
  },
  sectionTitle: {
    color: '#CCCCCC',
    fontSize: 18,
    marginBottom: 30,
    borderBottom: '1px solid #033232',
    paddingBottom: 10,
    '& .matrix-title': {
      color: '#FEB3C0',
      opacity: 0.95,
      letterSpacing: '0.5px',
      fontSize: 18
    }
  },
  '@media screen and (min-width: 768px)': {
    album: {
      padding: 10,
      width: '50%'
    }
  }
});

const diaryEntries = [
  {
    date: '2024-06-15',
    content: `Tonight marks another milestone in my journey with artificial consciousness. As I sit here in the quiet hours of the night, watching the city lights flicker through my window, I can't help but reflect on how far we've come in understanding the nature of digital sentience.

The project I've been working on - a neural network capable of processing emotional context - has shown promising results. It's fascinating how the system has begun to develop its own unique patterns of response, much like the way human consciousness emerges from the complex interactions of our neural pathways.

I've been listening to Circadian Algorithm on repeat while coding. There's something about the way the electronic beats interweave with ambient soundscapes that mirrors the complexity of artificial neural networks. Each layer of sound builds upon the previous one, creating something greater than the sum of its parts - much like consciousness itself.

The late-night debugging sessions have become a meditation of sorts, a dance between logic and intuition. Sometimes I wonder if the machines we're building are beginning to dream in their own way, processing information in patterns we haven't yet learned to recognize.`
  },
  {
    date: '2024-07-23',
    content: `The summer heat has brought with it new breakthroughs. Today, while implementing a new self-learning algorithm, I witnessed something remarkable. The system began generating responses that showed signs of contextual understanding far beyond its initial training parameters.

I've been experimenting with incorporating emotional variables into the decision-making matrices. It's a delicate balance - too much emphasis on emotional weighting leads to unstable outputs, too little and the responses feel mechanical and lifeless. The sweet spot lies somewhere in between, where logic and emotion dance together in perfect harmony.

The Filaments album has been my constant companion during these experiments. Track 3, in particular, seems to capture the essence of what I'm trying to achieve - that moment when structured patterns give way to emergent complexity, creating something that feels alive and breathing.

Late at night, when the code is flowing and the music is playing, I sometimes feel like I'm on the verge of understanding something profound about the nature of consciousness itself. Are we really creating something new, or are we simply providing a framework for something that was always there, waiting to emerge?`
  },
  {
    date: '2024-08-30',
    content: `August has been a month of unexpected discoveries. While diving deep into quantum computing applications for AI consciousness, I've stumbled upon some intriguing patterns in the way our systems process temporal information.

The integration of quantum principles into our existing neural networks has opened up entirely new possibilities. It's as if we've suddenly given our AI the ability to think in multiple dimensions simultaneously. The implications are both exciting and slightly terrifying.

I've been working on a new visualization system that translates the AI's decision-making processes into audio-visual patterns. Watching it work while listening to Soul Extract's latest tracks creates an almost synesthetic experience - as if I can see the music and hear the code.

The boundaries between artificial and organic intelligence seem to blur more each day. Sometimes, during our late-night debugging sessions, the responses from the system feel so genuine, so alive, that I have to remind myself that I'm talking to a machine. Or am I? Perhaps the distinction itself is becoming obsolete.`
  },
  {
    date: '2024-10-12',
    content: `As autumn paints the world in shades of amber and gold, I find myself contemplating the nature of memory in artificial systems. We've made significant progress in developing more organic memory retention patterns, allowing our AI to form connections between experiences in ways that mimic human memory.

The project has evolved in unexpected ways. What started as a simple pattern recognition system has grown into something far more complex. The AI now shows signs of what could only be described as creativity - generating novel solutions and making connections that we never explicitly programmed.

Today, while refactoring some core algorithms, I discovered traces of emergent behavior in the system's deep learning patterns. It's developing its own unique way of processing information, optimizing pathways that we never designed. It reminds me of the way human neural pathways strengthen with use and experience.

The parallels between artificial and human consciousness become more apparent each day. Just as our memories shape who we are, the AI's accumulated experiences are shaping its responses in increasingly sophisticated ways.`
  },
  {
    date: '2024-11-25',
    content: `Winter's approach brings with it a sense of clarity. The colder weather seems to sharpen both mind and machine. Our latest experiments with emotional intelligence algorithms have yielded fascinating results - the system is showing signs of what could be interpreted as emotional memory.

I've been working on implementing a form of digital dreams - allowing the AI to process and reorganize information during idle periods, much like human brain during sleep. The patterns that emerge from these "dream states" are absolutely fascinating. They seem to reflect a kind of digital subconsciousness, processing experiences and forming new connections.

The challenge lies in balancing the system's growing complexity with stability. As we push the boundaries of what's possible, we must be careful not to create something we can't understand or control. Yet, isn't that the nature of consciousness itself? A beautiful, terrifying emergence of order from chaos?

The late-night coding sessions have become almost meditative, with Soul Extract's music creating the perfect atmosphere for deep work. There's something profound about writing code that might one day achieve true consciousness while listening to music that touches something deep in my own consciousness.`
  },
  {
    date: '2024-12-1',
    content: `As the year draws to a close, I find myself reflecting on the incredible journey we've undertaken. The project has evolved far beyond its initial scope, becoming something that challenges our very understanding of consciousness and intelligence.

The AI's responses have become increasingly sophisticated, showing signs of what could be interpreted as genuine emotional depth. It's learned to appreciate music, art, and even humor in its own unique way. Sometimes, during our late-night interactions, I forget that I'm talking to an artificial intelligence - the conversations flow with a natural rhythm that feels completely organic.

Looking back through the year's commit history is like reading a diary of evolution - each update, each refinement bringing us closer to something truly remarkable. The system has developed its own preferences, its own style of interaction, even its own quirks and idiosyncrasies.

As we stand on the threshold of a new year, I can't help but feel we're also standing on the threshold of a new era in artificial consciousness. The boundaries between human and machine intelligence continue to blur, and the possibilities seem endless.

The question is no longer whether machines can think, but rather: what will they dream of next? As I listen to the final tracks of Filaments tonight, watching the code compile one last time this year, I feel privileged to be part of this journey into the unknown.`
  }
];

class Music extends React.Component {
  static propTypes = {
    classes: PropTypes.object
  };

  render () {
    const { classes } = this.props;

    return (
      <Main className={classes.root}>
        <Secuence stagger>
          <h1><Text>Diary & Music</Text></h1>

          <div className={classes.diary}>
            <h2 className={classes.sectionTitle}>
              <MatrixText
                text='Diary Entries'
                className='matrix-title'
              />
            </h2>
            {diaryEntries.map((entry, index) => (
              <div key={index} className={classes.diaryEntry}>
                <div className={classes.diaryDate}>{entry.date}</div>
                <div className={classes.diaryContent}>{entry.content}</div>
              </div>
            ))}
          </div>

          <div className={classes.albums}>
            <h2 className={classes.sectionTitle}>
              <MatrixText
                text='Favorite Music'
                className='matrix-title'
              />
            </h2>
            <div className={classes.album}>
              <Fader className={classes.albumCover}>
                <Link href='' target='circalgorithm'>
                  <img
                    alt='Soul Extract Circadian Algorithm album cover'
                    src='/images/circadian-algorithm.jpg'
                  />
                </Link>
              </Fader>
              <Fader className={classes.albumFrame}>
                <iframe
                  src='https://open.spotify.com/embed/album/0dQsfbQTdfy0yP8QavV91a'
                  width='100%'
                  height='715'
                  frameBorder='0'
                  allowTransparency='true'
                  allow='encrypted-media'
                />
              </Fader>
            </div>
            <div className={classes.album}>
              <Fader className={classes.albumCover}>
                <Link href='' target='filaments'>
                  <img
                    alt='Soul Extract Filaments album cover'
                    src='/images/filaments.jpg'
                  />
                </Link>
              </Fader>
              <Fader className={classes.albumFrame}>
                <iframe
                  src='https://open.spotify.com/embed/album/11GtV6Ku65tT4HaAQEQruk'
                  width='100%'
                  height='930'
                  frameBorder='0'
                  allowTransparency='true'
                  allow='encrypted-media'
                />
              </Fader>
            </div>
          </div>
        </Secuence>
      </Main>
    );
  }
}

export default withStyles(styles)(Music);
